<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["chartData", "options"],
  methods: {
    addGradient() {
      const baseColor = this.$vuetify.theme.dark
        ? "246, 184, 36"
        : "120, 104, 230";
      this.gradient = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 300);
      this.gradient.addColorStop(0, `rgba(${baseColor}, 0.8)`);
      this.gradient.addColorStop(1, `rgba(${baseColor}, 0.1)`);
      this.chartData.datasets[0].backgroundColor = this.gradient;
    },
    addDollarSign() {
      this.addPlugin({
        id: "dollar_sign",
        afterDraw: function(chart) {
          var ctx = chart.ctx;
          ctx.fillStyle = "gray";
          ctx.fillText("($)", chart.width - 20, chart.height - 10);
        },
      });
    },
    drawLabel() {
      const current_budget_label = this.$t("current budget");
      const fontColor = this.$vuetify.theme.themes[this.theme].chartFont;
      this.addPlugin({
        id: "current_budget_label",
        beforeDraw: function(chart) {
          let ctx = chart.ctx;
          let ds = chart.data.datasets[0];
          let x_position = chart.scales["time-axis"].getPixelForValue(
            ds.current_budget
          );
          let y_position_end = chart.scales["y-axis"].getPixelForValue(
            chart.scales["y-axis"].end
          );
          ctx.clearRect(0, 0, chart.width, chart.height);
          ctx.textAlign = "center";
          ctx.fillStyle = "grey";
          ctx.font = "normal 12px Arial";
          ctx.textBaseline = "middle";
          ctx.fillStyle = fontColor; //<======= here
          ctx.fillText(current_budget_label, x_position, y_position_end);
        },
      });
    },
    drawLine() {
      const line_color = this.$vuetify.theme.dark ? "gray" : "green";
      this.addPlugin({
        id: "anno_line",
        beforeDatasetsDraw: function(chart) {
          var ctx = chart.ctx;
          var ds = chart.data.datasets[0];
          if (ds && chart.scales && chart.scales["time-axis"]) {
            var x_position = chart.scales["time-axis"].getPixelForValue(
              ds.current_budget
              //Number(ds.current_budget)
            );
            let y_position_start = chart.scales["y-axis"].getPixelForValue(
              chart.scales["y-axis"].start
            );
            let y_position_end = chart.scales["y-axis"].getPixelForValue(
              chart.scales["y-axis"].end
            );

            ctx.save();
            ctx.strokeStyle = line_color;
            ctx.beginPath();
            ctx.setLineDash([10, 10]);
            ctx.moveTo(x_position, y_position_end);
            ctx.lineTo(x_position, y_position_start);
            ctx.stroke();
            ctx.beginPath();
            ctx.restore();
          }
        },
      });
    },
    drawOptimizedPoint() {
      const theme = this.$vuetify.theme.dark ? "dark" : "light";
      const pointColor = this.$vuetify.theme.themes[theme].borderColor;
      //const pointColor = this.$vuetify.theme.dark ? "red" : "green";
      this.addPlugin({
        id: "optimized_line",
        beforeDatasetsDraw: function(chart) {
          var ctx = chart.ctx;
          ctx.fillStyle = pointColor;
          var ds = chart.data.datasets[0];
          if (ds && chart.scales && chart.scales["time-axis"]) {
            var x_position = chart.scales["time-axis"].getPixelForValue(
              ds.optimized_budget
            );

            let y_position = chart.scales["y-axis"].getPixelForValue(
              ds.optimized_sales
            );
            ctx.beginPath();
            ctx.arc(x_position, y_position, 7, 0, 2 * Math.PI);
            ctx.fill();
          }
        },
      });
    },
    drawChart() {
      this.addGradient();
      this.drawLine();
      this.drawOptimizedPoint();
      this.drawLabel();

      this.addDollarSign();
      this.renderChart(this.chartData, this.options);
    },
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  watch: {
    chartData() {
      this.drawChart();
    },
  },
  mounted() {
    //this.addGradient()
    this.addDollarSign();
    this.drawLine();
    this.drawOptimizedPoint();
    this.renderChart(this.chartData, this.options);
    this.drawChart();
  },
  created() {},
};
</script>

<style></style>
